import React, { useEffect, useState } from 'react';
import "../css/mobileNav.scss";
import "../css/nav.css";
import logo from "../images/LogoLong.png";
import AniLink from "gatsby-plugin-transition-link/AniLink";


// markup
const MobileNavigation = () => {
   const [isOpen, setMenu] = useState("false");
   const menuClick = (e) => {
      setMenu(!isOpen);
   }
   useEffect(() => {
      return () => { }
   }, []);
   return (
      <header id="m-header">
         <AniLink fade to="/" className="m-logo"><img id="m-logo" src={logo} alt="header logo"></img></AniLink>
         <nav className={isOpen ? 'm-nav' : 'm-nav m-open'} onClick={menuClick} aria-hidden="true">
            <ul className="m-nav-ul">
               <li><AniLink fade to="/" className="" onClick={menuClick}>Home</AniLink></li>
               <li><AniLink fade to="/talent" onClick={menuClick}>Talent</AniLink></li>
               <li><AniLink fade to="/guideline" onClick={menuClick}>Guidline</AniLink></li>
               {/* <li><AniLink fade to="/audition" onClick={menuClick}>Audition</AniLink></li> */}
               <li><AniLink fade to="/contact"  onClick={menuClick}>Contact</AniLink></li>
               <li><a href="https://www.youtube.com/channel/UCDZ9IS3qPuacdG_GhGiB7Gg" target="_blank" className="" rel="noreferrer"  onClick={menuClick}>Youtube</a></li>
               <li><a href="https://twitter.com/_ProjectNebula" target="_blank" className="" rel="noreferrer" onClick={menuClick}>Twitter</a></li>
            </ul>
         </nav>
         <div className='menuWrapper'>
            <div className={isOpen ? 'menu' : 'menu m-open'} data-menu="1" onClick={menuClick} aria-hidden="true">
               <div className="icon-left"></div>
               <div className="icon-right"></div>
            </div>
         </div>
      </header>
   )
}

export default MobileNavigation
