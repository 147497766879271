export const onInitialClientRender = () => {
   
   setTimeout(() => {
      if (document.getElementById("loader-wrapper")) {
         document.getElementById("loader-wrapper").classList.add("finished");
      }
   }, 800);
};

// export const onInitialClientRender = () => {
//    setTimeout(() => {
//       if (document.getElementById("loader-wrapper")) {
//          document.getElementById("loader-wrapper").style.display = "flex";
//       }
//    }, 0);
// };
export const onRouteUpdate = () => {
   setTimeout(() => {
      if (document.getElementById("loader-wrapper")) {
         document.getElementById("loader-wrapper").classList.add("finished");
      }
   }, 800);
};