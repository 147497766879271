import React, { useEffect } from 'react';
import Navigation from './nav';
import "../css/layout.css";
import { Helmet } from 'react-helmet';
import riseupLogo from "../images/RiseupLogoWhite.png";
import MobileNavigation from './mobileNav';

import Loader from './loader';

export default function Layout({ children }) {

    useEffect(() => {
        document.title="PROJECT NEBULA（プロジェクトネブラ）公式サイト";
    }, []);
    return (
        <div className="layout">
            <Helmet>
                <meta name="description" content="Vtuberプロジェクト「PROJECT NEBULA」の公式サイトです。" />
                <link rel="preconnect" href="https://fonts.googleapis.com"></link>
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin></link>
                <link href="https://fonts.googleapis.com/css2?family=M+PLUS+1+Code:wght@600&family=Zen+Kaku+Gothic+New&display=swap" rel="stylesheet"></link>
            </Helmet>
            <Loader />
            <Navigation />
            <MobileNavigation />
            <div className="content">
                {children}
            </div>
            <footer className="footer_container">
                <a href="https://www.riseup-inc.com/" target="_blank" className='company_logo_wrapper' aria-label="riseup sitek" rel="noreferrer"><img id="company_logo" src={riseupLogo} alt="riseup logo"></img></a>
                <div className="footer">CopyrightⒸ2022 RISEUP INC. All Rigths Reserved</div>
            </footer>
            
        </div>
    )
}