import React from "react";
import "../css/loader.scss"
// import LoaderSVG from "../images/loader_logo.png";

const Loader = () => {
  return (
    <div id="loader-wrapper">
      {/* <img src={LoaderSVG} alt="GIF Image" /> */}
    </div>
  );
};
export default Loader;