import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import "../css/nav.css";
import logo from "../images/LogoLong.png";
import AniLink from "gatsby-plugin-transition-link/AniLink";

// markup
const Navigation = () => {
    useEffect(() => {
        return () => { }
    }, []);
    return (
        <nav id="nav-bar">
            <Helmet>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
            </Helmet>
            <div className="nav-item">
                <AniLink fade to="/" className="logo"><img id="logo" src={logo} alt="header logo"></img></AniLink>
                {/* <AniLink fade to="/" className="logo"><img id="logo" src={logo}></AniLink> */}
                <AniLink fade to="/">Home</AniLink>
                <AniLink fade to="/talent">Talent</AniLink>
                <AniLink fade to="/guideline">Guidline</AniLink>
                {/* <AniLink fade to="/audition">Audition</AniLink> */}
                <AniLink fade to="/contact">Contact</AniLink>
                {/* <a href="/">Contact</a> */}
            </div>
            <div className="socials">
                <a href="https://www.youtube.com/channel/UCDZ9IS3qPuacdG_GhGiB7Gg" target="_blank" className="" rel="noreferrer">Youtube</a>
                <a href="https://twitter.com/_ProjectNebula" target="_blank" className="" rel="noreferrer">Twitter</a>
            </div>
        </nav>
    )
}

export default Navigation
